@text-color: white;
@background-color: black;
@font: 'Helvetica';

* {
    margin: 0;
    padding: 0;
}

a {
    pointer-events: all;
}

html {
    overflow: hidden;
}

body {
    opacity: 0;
    transition: opacity 1s ease-out;
}

.dg.a {
    position: absolute;
    pointer-events: all;
    right: 0;
    height: min-content;
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: 0;
    cursor: grab;
}

body {
    background: @background-color;
    overflow: hidden;
    font-family: @font;

    @media (max-width: 1024px) {
        overflow-y: auto;
        overflow-x: hidden !important;
    }
}

audio {
    position: absolute;
    visibility: hidden;
    opacity: 0;
}

#tooltip {
    position: absolute;
    width: 25vw;
    left: calc(37.5vw - 20px);
    top: 50px;
    background: radial-gradient(#101010, #252525 100%);
    border-radius: 30px;
    padding: 20px 4px;
    transition: all 0.35s ease-out;
    color: white;
    text-align: center;
    transform: translateY(10px);
    text-shadow: 3px 3px 1px black;
    z-index: 100;

    @media(max-width: 1024px) {
        left: calc(25vw - 30px);
        width: 50vw;
        padding: 30px;
        top: 65vh;
    }
}

.main {
    color: @text-color;
    position: absolute;
    width: 100dvw;
    height: 100vh;
    pointer-events: none;
    user-select: none;

    @media(max-width: 1024px) {
        font-size: 1em !important;
        overflow: hidden;
    }

    h1 {
        font-size: 24px !important;
    }

    h2 {
        font-size: 12px;
    }

    h1, h2, h3, h4 {
        color: @text-color;
        margin: 0px;
        font-weight: lighter;

        @media(max-width: 1024px) {
            font-size: 1em !important;
        }
    }

    a, .project {
        color: @text-color;
        width: max-content;

        &:hover {
            text-decoration: none;
        }
    }

    section {
        width: max-content;
        margin-bottom: 40px;
        font-weight: lighter;

        @media (min-width: 768px) {
            margin-bottom: 70px;
        }

        @media (max-height: 768px) {
            margin-bottom: 0px;
        }

        .box {
            color: @text-color;
            width: max-content;
            padding: 10px;
            // box-shadow: -6px 6px 9px 1px rgba(0,0,0,0.5);

            @media (min-width: 768px) {
                margin-top: 50px;
                margin-bottom: 20px;
            }
        }

        .company-link {
            text-decoration: none;
            color: @text-color;
            transition: text-decoration 0.3s ease-in-out;

            &:hover{
                text-decoration: underline;
                color: @text-color;
            }
        }
    }

    #content {
        position: absolute;
        left: 0px;
        margin: 0px;
        z-index: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
        perspective: 100px;
        transform-style: preserve-3d;

        @media (max-width: 1024px) {
            height: 30%;
            bottom: 0px;
            position: relative;
        }

        @media (min-width: 900px) {
            top: 0px;
        }


        #landing {
            margin-top: 8vh;
            height: 100%;
            transform-style: preserve-3d;
            perspective: 1000px;
            pointer-events: none;

            @media(max-width:1370px) {
                min-height: 40vh;
            }


            .front, .behind, .hello {
                position: absolute;
                margin: 0 auto;
                text-align: center;
                width: 100%;
                height: 100%;
                perspective: 1000px;
                opacity: 0.333;
                transition: opacity 0.7s ease;
                pointer-events: none;
            }

            .front {
                top: 13vh;
                right: 50px;
                animation: float 3s infinite ease-in-out;

                img {
                    transform: translateZ(200px);
                }
            }

            .poster {
                margin: 0 auto;
                opacity: 0;
                transition: opacity 1s ease-out;
                width: min-content;
                top: 100px;
                position: relative;
                max-width: 50vw;

                img {
                    width: 50vw;
                    // filter: blur(3px);
                    border-radius: 20px;
                }
            }

            .hello {
                font-size: 150px;
                color: @text-color;
                text-shadow: 2px 2px 4px black;
                // transform: translateZ(-20px);

                // @media(max-width:1370px) {
                //     font-size: 42vw !important;
                //     top: 0px !important;
                // }
            }

            .behind {
                left: 50px;
                bottom: 2vh;
                animation: float2 4s infinite ease-in-out;
                transform: translateZ(-320px);
            }

            .helper {
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }

            img {
                vertical-align: middle;
                max-width:100vw;
            }
        }
    }

    .resume {
        transform: rotate(-90deg);
        position: absolute;
        left: 2em;
        bottom: 20vh;
        width: 0%;
        font-size: 16px;

        @media(max-width: 1024px) {
            display: none;
        }

        @media(max-height: 840px) {
            display: none;
        }

        .box {
            position: relative;
            width: 100vh;
            text-align: center;
            padding: 0px;
            right: calc(75vh - 160px);
        }
    }

    .copyright-section {
        position: absolute;
        right: 3em;
        bottom: 0%;

        @media (max-width: 1024px) {
            opacity: 0;
        }
    }

    .headings, .link-section {
        font-size: 16px;
        margin-left: 3em;
        z-index: 2;

        @media (max-width: 1024px) {
            margin-left: 0em;
        }

        .subtitle {
            text-decoration: underline;
            margin-bottom: 10px;
        }

        .name a {
            text-decoration: none;
            color: @text-color;
        }

        h1 {
            margin: 0px;
        }

        h2 {
            margin: 0px;

            &.description {
                margin-top: 15px;
            }
        }

        .project {
            padding: 4px 2px;
            margin: 4px 0;
            letter-spacing: 0px;
            transition: letter-spacing 1.2s ease-out, opacity 0.3s ease-out;
            display: block;
            text-decoration: none;

            &:hover {
                letter-spacing: 6px;
                opacity: 1 !important;
            }
        }
    }

    .headings.hovering {
        .project {
            opacity: 0.5;
        }
    }

    .link-section {
        position: absolute;
        bottom: 0px;

        a {
            margin: 0px 5px;
        }
        
        @media (max-width: 1024px) {
            right: 10px;
            font-size: 0.82em;
            bottom: 10px;
        }
    }
}

@keyframes float {
    0% {
        transform: translateY(-10px);
    }
    50% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-10px);
    }
}

@keyframes float2 {
    0% {
        transform: translateY(-20px) translateZ(-90px);
    }
    50% {
        transform: translateY(0px) translateZ(-90px);
    }
    100% {
        transform: translateY(-20px) translateZ(-90px);
    }
}

